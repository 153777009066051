html,
body {
  margin: 0;
  /* 000b27 - background color pure */
}

html,
body,
#root,
.chrome,
.underlay,
canvas.screen,
.overlay {
  height: 100%;
  width: 100%;
}

/* Utilities */

.inset-0 {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

/* Bungee */

@font-face {
  font-family: "Bungee Regular";
  font-display: block;
  src: url("/Bungee-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Bungee Regular Rotated";
  font-display: block;
  src: url("/BungeeLayersRotated-Regular.woff2") format("woff2");
}

.bungee-line {
  height: 1em;
  margin: 0;
}

.bungee-line > div {
  position: relative;
  /* .2 is too little on Firefox, .25 is too much on Chromium... */
  top: -0.225em;
}

.bungee-line {
  /* color: #464638; */
  transition-property: color;
  transition-duration: 300ms;
}

/* .bungee-line.alarmed-redeemed {
  color: #464638;
}

.bungee-line.alarmed-newly {
  color: #747445;
} */

.label-line {
  position: absolute;
}

.label-line.rotated {
  transform-origin: 0 0;
  transform: translateX(1em) rotate(90deg);
}

.labels-sheet {
  font-family: Bungee Regular Rotated;
  left: 0;
  right: 0;
  top: 0.25em;
  bottom: .25em;

  pointer-events: none;
  touch-action: none;
  font-size: 20px;
  position: absolute;
  overflow: hidden;
  white-space: nowrap;
  color: white;
}

.labels-sheet.horizontal {
  font-family: Bungee Regular;
  left: 0.25em;
  right: 0.25em;
  top: 0;
  bottom: 0;
}

/* Chrome */

.chrome {
  position: relative;
  color: beige;
  opacity: 0;
  transition-duration: 200ms;
  transition-property: opacity;
}

.chrome.presentable {
  opacity: 1;
}

canvas.screen,
.underlay,
.overlay {
  position: absolute;
}

.overlay {
  overflow: hidden;
  opacity: 0;
  background: rgba(0, 0, 0, 0);

  transition-duration: 200ms;
  transition-property: "opacity";
}

.overlay.overlay-on {
  opacity: 1;
}

.overlay .timeline {
  position: absolute;
  color: whitesmoke;
  pointer-events: none;

  inset: 0;
}

.timeline-border {
  position: absolute;
}

.chrome:not(.horizontal) .overlay .timeline-border {
  border-top: 2px solid whitesmoke;
  left: 0;
  right: 0;
}

.chrome.horizontal .overlay .timeline-border {
  border-left: 2px solid whitesmoke;
  top: 0;
  bottom: 0;
}

.overlay .timeline .timeline-center {
  position: absolute;
  white-space: nowrap;
}

.overlay .timeline .timeline-center > * {
  position: absolute;
}

.overlay .timeline .timeline-channel-side .timeline-name-indicator {
  font-weight: bold;
  display: flex;
  align-items: baseline;
}

.overlay .timeline .timeline-level-indicator {
  background-color: #141d35;
  border-radius: 3px;
  min-height: 20px;
  padding: 2px 6px;
  font-size: 12px;
  font-weight: 800;
}

.overlay .timeline .timeline-channel-side .timeline-name-gap {
  width: 4px;
}

.overlay .timeline .timeline-channel-side .timeline-parsing-error-indicator {
  /* position: absolute; */
  font-weight: bold;
  font-style: italic;
}

.overlay .timeline .timeline-small-side > *,
.overlay .timeline .timeline-channel-side > * {
  font-weight: bold;
  margin: 10px 15px;
}

.overlay .timeline:not(.is-right-rather-than-left) .timeline-name-indicator {
  flex-direction: row-reverse;
}

.overlay .timeline:not(.is-right-rather-than-left) .timeline-small-side {
  right: unset !important;
}

.overlay .timeline:not(.is-right-rather-than-left) .timeline-channel-side {
  left: unset !important;
  text-align: right;
}

.overlay .timeline.is-right-rather-than-left .timeline-small-side {
  left: unset !important;
  text-align: right;
}

.overlay .timeline.is-right-rather-than-left .timeline-channel-side {
  right: unset !important;
}


.overlay .timeline:not(.is-top-rather-than-bottom) .timeline-small-side,
.overlay .timeline:not(.is-top-rather-than-bottom) .timeline-channel-side {
  top: unset !important;
}

.overlay .timeline.is-top-rather-than-bottom .timeline-small-side,
.overlay .timeline.is-top-rather-than-bottom .timeline-channel-side {
  bottom: unset !important;
}



.overlay .laneline {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.1);
  outline: solid 2px whitesmoke;
  pointer-events: none;
}

.chrome:not(.horizontal) .overlay .laneline {
  top: 0;
  bottom: 0;
}

.chrome.horizontal .overlay .laneline {
  left: 0;
  right: 0;
}

.overlay .laneline > * {
  position: absolute;
}

.overlay > .lane-chart > .lane {
  position: absolute;
  top: 0;
  bottom: 0;
}

.overlay .drawer {
  position: absolute;
  top: 0;
  bottom: 0;
  color: black;
  background-color: whitesmoke;
  width: 45%;

  overflow: auto;

  opacity: 0;
  transition-duration: 200ms;
  transition-property: "opacity";

  display: flex;
  flex-direction: column;
}

.overlay .drawer .drawer-footer {
  background-color: gainsboro;
}

.overlay.overlay.overlay-clamped .drawer {
  opacity: 1;
}

.drawer.drawer-is-right {
  right: 0;
}

.drawer:not(.drawer-is-right) {
  left: 0;
}

.drawer-header {
  display: flex;
  justify-content: space-between;
  margin: 5px;
}

.drawer-content {
}

.drawer-content > * {
  padding: 10px;
}

.drawer-content label {
  font-variant: small-caps;
  margin-bottom: 0;
}

.drawer-content-message {
}

.drawer-content-label {
  font-weight: bold;
}

.drawer-content pre {
  white-space: pre-wrap;
}

.details-preformatted,
.drawer-content-raw {
  word-break: break-all;
  white-space: pre-wrap;
  font-family: "Courier New", Courier, monospace;
}

.chrome > .status-bar {
  position: absolute;
  bottom: 0;
  color: white;
}

.chrome-display {
  position: relative;
  height: 100%;
}

.curtain {
  top: 0;
  opacity: 1;
  background-color: #000b27;
  transition-duration: 100ms;
  transition-property: "opacity";

  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.curtain.open {
  pointer-events: none;
  opacity: 0;
}

.address-frame {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 5px;
  border: 0px solid white;
}

/* Teaser */

.teaser {
  position: fixed;

  bottom: 0;
  right: 0;

  width: 50%;

  padding: 20px;

  border: none;

  color: black;
}

@media (max-width: 800px) {
  .teaser {
    width: 100%;
  }
}